import { createReducer } from "@reduxjs/toolkit";

import actions from "../actions";

const { cardsActions } = actions;

export const initialState = {
  isMoving: false,
  clickedUrl: null,
  cardsList: [],
  cardsInField: {}, 
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cardsActions.setCards, (state, action) => {
    const cards = action.payload;

    return {
      ...state,
      cardsList: cards,
    };
  });
  builder.addCase(cardsActions.setIsMoving, (state, action) => {
    const isMoving = action.payload;

    return {
      ...state,
      isMoving: isMoving,
    };
  });
  builder.addCase(cardsActions.setClickedUrl, (state, action) => {
    const clickedUrl = action.payload;

    return {
      ...state,
      clickedUrl: clickedUrl,
    };
  });
  builder.addCase(cardsActions.setCardsInField, (state, action) => {
  const cardsInField = action.payload;

    return {
      ...state,
      cardsInField 
    }
  });
});
