import { createAction } from "@reduxjs/toolkit";

const setCards = createAction("cards/setCards", (cards) => ({
  payload: cards,
}));

const setIsMoving = createAction("cards/setIsMoving", (isMoving) => ({
  payload: isMoving,
}));

const setClickedUrl = createAction("cards/setClickedUrl", (clickedUrl) => ({
  payload: clickedUrl,
}));


const setCardsInField = createAction("cards/setCardsInField", (cards) => ({
  payload: cards,
}));

export default { setCards, setIsMoving, setClickedUrl, setCardsInField };
