import cardsActions from "./cards";
import decksActions from "./decks";
import panelsActions from "./panels";
import fieldActions from "./field";

export default {
  cardsActions,
  decksActions,
  panelsActions,
  fieldActions,
};
