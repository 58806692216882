import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";

import {
  DndProvider,
  TouchTransition,
  MouseTransition,
} from "react-dnd-multi-backend";

import BoardsWrapperDnD from "./components/BoardsWrapperDnD";

import "./App.css";

export const HTML5toTouch = {
  backends: [
    {
      id: "html5",
      backend: HTML5Backend,
      transition: MouseTransition,
      preview: false,
    },
    {
      id: "touch",
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: false,
      transition: TouchTransition,
    },
  ],
};

const App = () => (
  <DndProvider options={HTML5toTouch}>
    <Router>
      <Route path="/" exact component={BoardsWrapperDnD} />
      <Route path="/playground" component={BoardsWrapperDnD} />
    </Router>
  </DndProvider>
);

export default App;
