/* eslint-disable no-console */

const env = process.env.NODE_ENV;

/**
 * Просто обертка над методами консоли. В прод-билде не вызывает
 * реальную консоль браузера
 */
export class Logger {
  /**
   * @property {boolean} - надо ли показывать консоль
   */
  showConsole: boolean = false;

  /**
   * Конструктор класса
   *
   * @param {string} environment - идентификатор среды сборки
   *
   */
  constructor(environment: string = 'production') {
    this.showConsole = environment === 'development';
  }

  /**
   * Обертка над console.log.
   * В production-режиме просто игнорирует выполнение
   *
   * @papam {...any} - любые аргументы
   * @returns {void}
   */
  log(...data: any[]): void {
    if (!this.showConsole) {
      return;
    }
    console.log(...data);
  }

  /**
   * Обертка над console.warn.
   * В production-режиме просто игнорирует выполнение
   *
   * @papam {...any} - любые аргументы
   * @returns {void}
   */
  warn(...data: any[]): void {
    if (!this.showConsole) {
      return;
    }
    console.warn(...data);
  }

  /**
   * Обертка над console.warn.
   * В production-режиме просто игнорирует выполнение
   *
   * @papam {...any} - любые аргументы
   * @returns {void}
   */
  error(...data: any[]): void {
    if (!this.showConsole) {
      return;
    }
    console.error(...data);
  }
}

export default new Logger(env);
