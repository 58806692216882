import React, { useCallback, useState, useEffect, useRef } from "react";
import { useDrag } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import clsx from "clsx";
import omitBy from "lodash/omitBy";

import actions from "../../store/actions";
import {
  getCards,
  getIsMoving,
  getClickedUrl,
  getCardsInField,
} from "../../store/selectors/cards";
import {
  getIsDecksPanelOpen,
  getIsStackPanelOpen,
} from "../../store/selectors/panels";
import { ItemTypes } from "../../utils/items";
import "./CardItem.scss";
import { getEmptyImage } from "react-dnd-html5-backend";
const { cardsActions } = actions;

let socket;

const CardItem = ({
  boardType,
  isOpen,
  imgUrl,
  rubashka,
  id,
  x,
  y,
  isShowControls,
  size,
  room,
  field,
  deckId,
  backUrl,
  activeDeckId,
  item,
  moveCounter,
}) => {
  const [{ isDragging }, cardRef, preview] = useDrag({
    type: ItemTypes.CARD,
    item: {
      type: ItemTypes.CARD,
      id,
      x,
      y,
      imgUrl,
      rubashka,
      isOpen,
      size,
      field,
      deckId,
      backUrl,
      moveCounter,
    },
    id,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const dispatch = useDispatch();
  const cards = useSelector(getCards);
  const cardsInField = useSelector(getCardsInField);
  const isMoving = useSelector(getIsMoving);
  const clickedUrl = useSelector(getClickedUrl);
  const isStackPanelOpen = useSelector(getIsStackPanelOpen);
  const isDecksPanelOpen = useSelector(getIsDecksPanelOpen);

  const updateCardsInField = (isOpen, size, isRemove) => {
    const newCardsInField = isRemove
      ? omitBy(cardsInField, (data, key) => key === imgUrl)
      : {
          ...cardsInField,
          [imgUrl]: {
            ...item,
            isOpen,
            size,
          },
        };

    dispatch(cardsActions.setCardsInField(newCardsInField));
  };

  useEffect(() => {
    if (room) {
      socket = io.connect({ transports: ["websocket"] });
    }
  }, [room]);

  useEffect(() => {
    if (isMoving && room) {
      dispatch(cardsActions.setIsMoving(false));
      socket.emit(
        "moved",
        room,
        cards,
        clickedUrl,
        { isDecksPanelOpen, isStackPanelOpen },
        () => {}
      );
    }
  }, [isMoving, room]);

  const flipHandler = () => {
    const newCards = cards.map((card) => {
      if (card.url === imgUrl) {
        const newCard = { ...card };
        newCard.isOpen = !isOpen;
        updateCardsInField(newCard.isOpen, size);
        return newCard;
      }
      return card;
    });

    dispatch(cardsActions.setCards(newCards));
    dispatch(cardsActions.setIsMoving(true));
  };

  const removeHandler = () => {
    const newCards = cards.map((card) => {
      if (card.url === imgUrl) {
        const newCard = { ...card };
        newCard.field = activeDeckId === deckId ? "stack" : null;
        newCard.size = 0;
        updateCardsInField(isOpen, size, true);
        return newCard;
      }
      return card;
    });

    dispatch(cardsActions.setCards(newCards));
    dispatch(cardsActions.setIsMoving(true));
  };

  const increaseHandler = () => {
    const newCards = cards.map((card) => {
      if (card.url === imgUrl) {
        const newCard = { ...card };
        if (newCard.size < 4) {
          newCard.size = newCard.size + 1;
          updateCardsInField(isOpen, newCard.size);
        }
        return newCard;
      }
      return card;
    });

    dispatch(cardsActions.setCards(newCards));
    dispatch(cardsActions.setIsMoving(true));
  };

  const decreaseHandler = () => {
    const newCards = cards.map((card) => {
      if (card.url === imgUrl) {
        const newCard = { ...card };
        if (newCard.size > 0) {
          newCard.size = newCard.size - 1;
          updateCardsInField(isOpen, newCard.size);
        }
        return newCard;
      }
      return card;
    });

    dispatch(cardsActions.setCards(newCards));
    dispatch(cardsActions.setIsMoving(true));
  };

  return (
    <div
      ref={cardRef}
      className={clsx("item", `size-${size}`)}
      style={
        boardType === "field"
          ? {
              background: `no-repeat center center / contain url(${
                isOpen
                  ? `${backUrl}${escape(imgUrl)}`
                  : `${backUrl}${escape(rubashka)}`
              })`,
              zIndex: 3,
              opacity: isDragging ? "0.1" : "1",
              position: "absolute",
              top: `${y - 5}px`, // отступ поля сверху
              left: `${x - 20}px`, // отступ поля слева
            }
          : {
              zIndex: 2,
              background: `no-repeat center center / contain url(${
                isOpen
                  ? `${backUrl}${escape(imgUrl)}`
                  : `${backUrl}${escape(rubashka)}`
              })`,
            }
      }
    >
      {isShowControls && (
        <>
          <button onClick={flipHandler} className="flipBtn">
            ↺
          </button>
          <button onClick={removeHandler} className="removeBtn">
            x
          </button>
          <button onClick={increaseHandler} className="increaseBtn">
            +
          </button>
          <button onClick={decreaseHandler} className="decreaseBtn">
            -
          </button>
        </>
      )}
    </div>
  );
};

export default CardItem;
