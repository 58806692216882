import { createReducer } from "@reduxjs/toolkit";

import actions from "../actions";

const { decksActions } = actions;

export const initialState = {
  activeDeckId: 10,
  decks: [],
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(decksActions.setActiveDeckId, (state, action) => {
    const activeDeckId = action.payload;

    return {
      ...state,
      activeDeckId: activeDeckId,
    };
  });

  builder.addCase(decksActions.setDecks, (state, action) => {
    const decks = action.payload;

    return {
      ...state,
      decks: decks,
    }
  })
});
