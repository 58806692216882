import { createReducer } from "@reduxjs/toolkit";

import actions from "../actions";

const { fieldActions } = actions;

export const initialState = {
  fieldSize: 0,
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(fieldActions.setFieldSize, (state, action) => {
    const fieldSize = action.payload;

    return {
      ...state,
      fieldSize: fieldSize,
    };
  });
});
