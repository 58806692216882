import { createAction } from "@reduxjs/toolkit";

const setActiveDeckId = createAction("decks/setActiveDeckId", (activeDeckId) => ({
  payload: activeDeckId,
}));

const setDecks = createAction("decks/setDecks", (deck) => ({
  payload: deck,
}));

export default { setActiveDeckId, setDecks };
