import { createReducer } from "@reduxjs/toolkit";

import actions from "../actions";

const { panelsActions } = actions;

export const initialState = {
  isStackPanelOpen: true,
  isDecksPanelOpen: true,
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(panelsActions.setIsStackPanelOpen, (state, action) => {
    const isStackPanelOpen = action.payload;

    return {
      ...state,
      isStackPanelOpen: isStackPanelOpen,
    };
  });

  builder.addCase(panelsActions.setIsDecksPanelOpen, (state, action) => {
    const isDecksPanelOpen = action.payload;

    return {
      ...state,
      isDecksPanelOpen: isDecksPanelOpen,
    }
  })
});
