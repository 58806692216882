import Api from "./common";

/**
 * fetchDecks
 * Загрузка списка всех колод
 *
 * @returns {Promise}
 */
export function fetchDecks() {
  return Api.baseApi.get(`/decks`);
}

/**
 - * fetchCards
 - * Загрузка всех карт колоды
 - *
 - * @param {String} cardName - Название колоды
 - *
 - * @returns {Promise}
 - */
export function fetchCards(deckId = "1") {
  return Api.baseApi.get(`/decks/${deckId}`);
}
