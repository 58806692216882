import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// import { createLogger } from 'redux-logger';

import reducer from './reducers';

const middleware = [...getDefaultMiddleware()];

// Логи в консоль выключены что бы не засорять процесс. Удобнее пользоавться расширением Redux DevTools
// if (process.env.NODE_ENV !== 'production') {
//   const logger = createLogger();
//   middleware = [...middleware, logger];
// }

const store = configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
