/**
 * В этом файле содержится общее для API
 */

import axios from "axios";
import applyConverters from "axios-case-converter";

import { backURL } from "../config";

// Ссылка на API
// const { API_URL } = process.env;
// Конфигурация, необходимая для всех запросов
const commonSettings = {
  credentials: "include",
};

export const createAxiosInstance = (baseURL) => {
  if (!baseURL) {
    throw new Error("baseURL is not spicified api");
  }
  return applyConverters(
    axios.create({
      ...commonSettings,
      baseURL,
    })
  );
};

const baseApi = createAxiosInstance(
  process.env.NODE_ENV === "development" ? `${backURL}/api/` : `${backURL}/api/` // заменить на /api когда будем катить на настоящий прод
);

export default { baseApi };
