import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import io from "socket.io-client";
import clsx from "clsx";
import shuffle from "lodash/shuffle";
import orderBy from "lodash/orderBy";
import { useDrop } from "react-dnd";
import { usePreview } from "react-dnd-preview";
import { v4 as uuidv4 } from "uuid";
import Popup from "reactjs-popup";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { backURL, frontURL } from "../../config";
import {
  getCards,
  getIsMoving,
  getClickedUrl,
  getCardsInField,
} from "../../store/selectors/cards";
import { getActiveDeckId, getDecks } from "../../store/selectors/decks";
import { getFieldSize } from "../../store/selectors/field";
import {
  getIsDecksPanelOpen,
  getIsStackPanelOpen,
} from "../../store/selectors/panels";
import { fetchCards, fetchDecks } from "../../api";
import actions from "../../store/actions";
import CardItem from "../CardItem/CardItem";
import { ItemTypes } from "../../utils/items";
import logger from "../../helpers/logger";
import "./BoardsWrapperDnD.scss";

let socket;

const { cardsActions, decksActions, panelsActions, fieldActions } = actions;

const MyPreview = () => {
  const { display, itemType, item, style } = usePreview();

  if (!display) {
    return null;
  }

  return (
    <div style={{ ...style, zIndex: "50" }}>
      <div
        className={clsx("item", !item.isOpen && "closed", `size-${item.size}`)}
        style={{
          background: `no-repeat center center / contain url(${
            item.isOpen
              ? `${backURL}${escape(item.imgUrl)}`
              : `${backURL}${escape(item.rubashka)}`
          })`,
        }}
      />
    </div>
  );
};

const BoardsWrapperDnD = ({ location }) => {
  const [socketRoom, setRoom] = useState(null);
  const [isModalOpen, setModalOpen] = useState(socketRoom);
  const toggleModal = (isOpen) => setModalOpen(isOpen);
  const ENDPOINT = "http://localhost:8000";

  const dispatch = useDispatch();

  const cards = useSelector(getCards);
  const clickedUrl = useSelector(getClickedUrl);
  const isMoving = useSelector(getIsMoving);
  const activeDeckId = useSelector(getActiveDeckId);
  const isStackPanelOpen = useSelector(getIsStackPanelOpen);
  const isDecksPanelOpen = useSelector(getIsDecksPanelOpen);
  const decks = useSelector(getDecks);
  const fieldSize = useSelector(getFieldSize);
  const cardsInField = useSelector(getCardsInField);

  const [innerClickedUrl, setInnerClickedUrl] = useState(clickedUrl);
  const [moveCounter, setMoveCounter] = useState(0);

  useEffect(() => {
    setInnerClickedUrl(clickedUrl);
  }, [clickedUrl]);

  useEffect(() => {
    if (socketRoom) {
      toggleModal(true);
    }
  }, [socketRoom]);

  useEffect(() => {
    const { room } = queryString.parse(location.search);
    if (room) {
      socket = io.connect({ transports: ["websocket"] });
      setRoom(room);

      socket.emit("join", room, () => {});
    }
  }, [location.search]);

  useEffect(() => {
    // setIsRecieve(false);
    const { room } = queryString.parse(location.search);
    const callback = (
      newCards,
      clickedUrl,
      { isStackPanelOpen, isDecksPanelOpen },
      fieldSize
    ) => {
      dispatch(cardsActions.setCards(newCards));
      dispatch(panelsActions.setIsDecksPanelOpen(isDecksPanelOpen));
      dispatch(panelsActions.setIsStackPanelOpen(isStackPanelOpen));
      dispatch(cardsActions.setClickedUrl(clickedUrl));
      dispatch(fieldActions.setFieldSize(fieldSize));
    };

    const joinCallback = (room) => {
      setRoom(room);
    };

    if (room) {
      socket.on("new move", callback);
      socket.on("room joined", joinCallback);

      return () => {
        socket.off("new move", callback);
        socket.off("room_joined", joinCallback);
      };
    }

    // setBoards(newBoards);

    // socket.on("new move", (boards) => setBoards([...boards]));
  }, [dispatch, location.search]);

  useEffect(() => {
    const { room } = queryString.parse(location.search);

    if (isMoving && room) {
      dispatch(cardsActions.setIsMoving(false));
      // if (room) {
      // console.log("Отправляем: ", socketRoom, cards, innerClickedUrl, {
      //   isDecksPanelOpen,
      //   isStackPanelOpen,
      // });
      socket.emit(
        "moved",
        socketRoom,
        cards,
        innerClickedUrl,
        { isDecksPanelOpen, isStackPanelOpen },
        fieldSize,
        () => {}
      );
      // }
    }
  }, [
    isDecksPanelOpen,
    isStackPanelOpen,
    isMoving,
    dispatch,
    isMoving,
    innerClickedUrl,
    socketRoom,
    fieldSize,
    location.search,
  ]);
  // setIsMove(true); // основной флаг, говорящий что мы походили

  // useEffect(() => {
  //   console.log('cards are moved');
  //   socket.emit("moved", cards, clickedUrl, () => {});
  // },[cards]);

  // const [cards, setCards] = useState([]);

  useEffect(async () => {
    async function loadDecks() {
      try {
        const response = await fetchDecks();

        return response.data;
      } catch (err) {
        logger.warn(err);
      }
    }

    async function loadCards(activeDeckId) {
      try {
        const response = await fetchCards(activeDeckId);

        return response.data;
      } catch (err) {
        logger.warn(err);
      }
    }

    const dataDecks = await loadDecks();
    const dataCards = await loadCards(activeDeckId);

    const rubashka = dataCards.image;
    const cards = dataCards.cards;
    const deckId = dataCards.id;
    const cardsState = cards
      ?.filter((card, id) => {
        return !cardsInField[card.image];
      })
      .map((card, id) => {
        return {
          id,
          title: `Карточка ${id}`,
          url: card.image,
          rubashka,
          isOpen: true,
          field: "stack",
          deckId,
          x: 0,
          y: 0,
          size: 0,
          moveCounter: 0,
        };
      });

    const storedCardsInField = orderBy(
      [...Object.values(cardsInField)],
      ["moveCounter"],
      "asc"
    );
    console.log("storedCardsInField: ", storedCardsInField);
    cardsState.push(...storedCardsInField); // тут мы добавляем карты которые были в поле в предыдущей колоде
    console.log("cardsState: ", cardsState);
    console.log("------");
    // FIXME: есть проблема в том, что порядок карт не фиксирован, а нам нужно последнюю походившую карту держать в конце,
    //  чтобы она перекрывала нижние карты
    // МОЖНО: при каждом ходе сохранять номер хода. А при пуше в новую колоду сортировать по этим номерам и обнулять их

    dispatch(cardsActions.setCards(cardsState)); // показываем новую колоду
    dispatch(decksActions.setDecks(dataDecks)); // обновляем список колод
  }, [dispatch, activeDeckId]);

  // useEffect(() => {
  //   dispatch(cardsActions.setCards(cards));
  // }, [dispatch, cards]);

  const changeField = (id, url, field, coords = { x: 0, y: 0 }) => {
    const card = cards.filter((card, i) => card.url === url);
    const replacedCard = { ...card[0] };
    replacedCard.writable = true;
    replacedCard.field = field;
    replacedCard.x = coords.x;
    replacedCard.y = coords.y;
    dispatch(
      cardsActions.setCards(
        cards.filter((card, i) => card.url !== url).concat(replacedCard)
      )
    );
    // setCards(cards.filter((card, i) => card.id !== id).concat(card[0]));

    dispatch(cardsActions.setClickedUrl(url));
    dispatch(cardsActions.setIsMoving(true));
  };

  const AppRef = useRef();

  const [{ isOverField }, dropField] = useDrop({
    accept: ItemTypes.CARD,
    drop: (item, monitor) => {
      const monitorItem = monitor.getItem();
      const sourceClientOffset = monitor.getSourceClientOffset();
      const xOffset = sourceClientOffset.x - 5;
      const yOffset = sourceClientOffset.y - 25;

      dispatch(cardsActions.setClickedUrl(item.url));
      setMoveCounter(moveCounter + 1);

      if (monitorItem.field === "stack") {
        // значит переносим карту из стека в поле
        // добавляем ее в список карт "в поле" чтобы потом их можно было пушить на поле при смене колоды и обновлении стола
        dispatch(
          cardsActions.setCardsInField({
            ...cardsInField,

            [monitorItem.imgUrl]: {
              ...monitorItem,
              field: "field",
              url: monitorItem.imgUrl,
              x: xOffset,
              y: yOffset,
              moveCounter: moveCounter,
            },
          })
        );
      }

      if (monitorItem.field === "field") {
        console.log("isOverField: ", isOverField);
        // двигаем карту внутри поля, при этом важно обновлять в сторе cardsInField чтобы при смене колоды поле было актуальным
        dispatch(
          cardsActions.setCardsInField({
            ...cardsInField,

            [monitorItem.imgUrl]: {
              ...monitorItem,
              field: "field",
              url: monitorItem.imgUrl,
              x: xOffset,
              y: yOffset,
              moveCounter: moveCounter,
            },
          })
        );
      }

      changeField(item.id, item.imgUrl, "field", {
        x: xOffset,
        y: sourceClientOffset.y - 25,
      });
    },
    collect: (monitor) => ({
      isOverField: !!monitor.isOver(),
    }),
  });

  const [{ isOverStack }, dropStack] = useDrop({
    accept: ItemTypes.CARD,
    drop: (item, monitor) => changeField(item.id, "stack"),
    collect: (monitor) => ({
      isOverStack: !!monitor.isOver(),
    }),
  });

  const randomizeCards = () => {
    dispatch(cardsActions.setCards(shuffle({ ...cards })));
    dispatch(cardsActions.setIsMoving(true));
  };

  const increaseFieldHandler = () => {
    dispatch(fieldActions.setFieldSize(fieldSize + 1));
    dispatch(cardsActions.setIsMoving(true));
  };

  const decreaseFieldHandler = () => {
    if (fieldSize > 0) {
      dispatch(fieldActions.setFieldSize(fieldSize - 1));
      dispatch(cardsActions.setIsMoving(true));
    }
  };

  const handleClickShowStack = useCallback(() => {
    dispatch(panelsActions.setIsStackPanelOpen(!isStackPanelOpen));
    dispatch(cardsActions.setIsMoving(true));
  }, [dispatch, isStackPanelOpen]);

  const flipAllCards = (isOpen) => {
    const newCards = cards.map((card) => {
      if (card.field === "stack" && card.isOpen !== isOpen) {
        const newCard = { ...card };

        newCard.isOpen = isOpen;
        return newCard;
      }
      return card;
    });

    dispatch(cardsActions.setCards(newCards));
    dispatch(cardsActions.setIsMoving(true));
  };

  const clearField = () => {
    const newCards = cards.map((card) => {
      if (card.field === "field") {
        const newCard = { ...card };

        newCard.size = 0;

        newCard.field = "stack";
        return newCard;
      }
      return card;
    });

    dispatch(cardsActions.setCardsInField({}));
    dispatch(cardsActions.setCards(newCards));
    dispatch(cardsActions.setClickedUrl(null));
    dispatch(cardsActions.setIsMoving(true));
  };

  const changeActiveDeck = (newActiveDeckId) => {
    dispatch(decksActions.setActiveDeckId(newActiveDeckId));
    dispatch(cardsActions.setClickedUrl(null));
    dispatch(cardsActions.setIsMoving(true));
  };

  const createOnlineSession = () => {
    const uuid = uuidv4();
    window.open(`?room=${uuid}`, "_blank");
  };

  const fieldHeight = useCallback(() => {
    // const heightDiff = fieldSize * 200 - 10;
    const heightDiff = fieldSize * 200;

    return `calc(100vh + ${heightDiff}px)`;
  }, [fieldSize]);

  // useScrollPosition(({ prevPos, currPos }) => {
  //   console.log(currPos.x);
  //   console.log(currPos.y);
  // });

  return (
    <div className="App" style={{ height: fieldHeight() }} ref={AppRef}>
      <div class="topbar">
        <ul class="mainNav">
          <li>
            <h1>Метафорические карты онлайн</h1>
          </li>
          <li>
            <a class="btn1" href="//metaforicheskie-karti.ru/">
              Покупка карт
            </a>
          </li>
          <li>
            <a
              class="btn1"
              href="//metaforicheskie-karti.ru/metaforicheskie-karty-obuchenie/bazovii-kurs/urok-1/"
            >
              Уроки по МАК
            </a>
          </li>
          <li>
            <a
              class="btn1"
              href="//metaforicheskie-karti.ru/metaforicheskie-karty-skachat/"
            >
              Скачать колоду
            </a>
          </li>
        </ul>
      </div>
      <div
        ref={dropField}
        className={"field"}
        onClick={() => {
          dispatch(cardsActions.setClickedUrl(null));
        }}
        // style={
        //   isOverField
        //     ? { backgroundColor: "red" }
        //     : { backgroundColor: "green" }
        // }
      >
        {cards
          .filter((item) => item.field === "field")
          .map((item) => (
            <div
              key={`card-${item.id}-${item.deckId}`}
              className={"fieldCardWrapper"}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(cardsActions.setClickedUrl(item.url));
              }}
            >
              <CardItem
                clickedUrl={clickedUrl}
                boardType={"field"}
                isOpen={item.isOpen}
                backUrl={backURL}
                imgUrl={item.url}
                rubashka={item.rubashka}
                id={item.id}
                x={item.x}
                y={item.y}
                field={item.field}
                deckId={item.deckId}
                size={item.size}
                isShowControls={item.url === clickedUrl}
                room={socketRoom}
                activeDeckId={activeDeckId}
                moveCounter={item.moveCounter}
                item={item}
              />
            </div>
          ))}
        <p class="desc">
          Просто перетяните нужную вам карту на стол и начинайте работать.
        </p>
      </div>
      <div
        ref={dropStack}
        className={clsx("stack", !isStackPanelOpen && "stackHidden")}

        // isOverStack - метод формы, он пока прямо не используется, но написал чтобы не забыть. Срабатывает при ховере одним элементом над другим
        // style={
        //   isOverStack
        //     ? { backgroundColor: "red" }
        //     : { backgroundColor: "green" }
        // }
      >
        <div className={"showStack"} onClick={handleClickShowStack}>
          <div className={clsx("arrow", !isStackPanelOpen && "up")} />
        </div>
        <div className="stackContent">
          <div className="stackButtons">
            <button onClick={randomizeCards}>Перемешать</button>
            <button onClick={() => flipAllCards(false)}>В закрытую</button>
            <button onClick={() => flipAllCards(true)}>В открытую</button>
            <button onClick={clearField}>Очистить стол</button>
            {/* {!socketRoom && ( */}
            {/* <button onClick={createOnlineSession}>Совместная сессия</button> */}
            {/* )} */}
            {socketRoom && (
              <button onClick={() => toggleModal(true)}>
                Показать ссылку на комнату
              </button>
            )}
            {/*<div className="zoomWrapper">*/}
            {/*  <button onClick={() => increaseFieldHandler()}>+</button>*/}
            {/*  <button onClick={() => decreaseFieldHandler()}>-</button>*/}
            {/*</div>*/}
          </div>
          <div className="stackCardsWrapper">
            {cards
              .filter((item) => item.field === "stack")
              .map((item) => (
                <div
                  key={`card-${item.id}-${item.deckId}`}
                  className={"itemWrapper"}
                  onClick={() => dispatch(cardsActions.setClickedUrl(item.url))}
                >
                  <CardItem
                    boardType={"stack"}
                    isOpen={item.isOpen}
                    backUrl={backURL}
                    imgUrl={item.url}
                    rubashka={item.rubashka}
                    id={item.id}
                    x={item.x}
                    y={item.y}
                    size={item.size}
                    field={item.field}
                    deckId={item.deckId}
                    isShowControls={item.url === clickedUrl}
                    room={socketRoom}
                    moveCounter={item.moveCounter}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className={clsx("decksPanel", !isDecksPanelOpen && "decksHidden")}>
        <div
          className={"showDeck"}
          onClick={() => {
            dispatch(panelsActions.setIsDecksPanelOpen(!isDecksPanelOpen));
            dispatch(cardsActions.setIsMoving(true));
          }}
        >
          <div
            className={clsx(
              "arrow",
              !isDecksPanelOpen && "left",
              isDecksPanelOpen && "right"
            )}
          />
        </div>
        <div className={"deckContentWrapper"}>
          <div className={"deckContent"}>
            {decks.map((deck) => (
              <div
                key={`deck-${deck.id}`}
                onClick={() => changeActiveDeck(deck.id)}
                className={clsx(
                  "item",
                  deck.id === activeDeckId && "activeDeck"
                )}
                style={{
                  background: `no-repeat center center / contain url(${backURL}/${escape(
                    deck.image
                  )})`,
                }}
              />
            ))}
          </div>
        </div>
      </div>
      <MyPreview />
      <Popup
        open={isModalOpen}
        closeOnDocumentClick
        onClose={() => toggleModal(false)}
        position="right center"
        modal
      >
        <div className="modal">
          <a className="close" onClick={() => toggleModal(false)}>
            &times;
          </a>
          <p>
            Ссылка совместной комнаты:{" "}
            <CopyToClipboard text={`${frontURL}?room=${socketRoom}`}>
              <span className={"roomLink"}>
                {frontURL}?room={socketRoom}
              </span>
            </CopyToClipboard>
          </p>
          Скопируйте ссылку нажав на выделенный текст и пришлите ее партнеру
        </div>
      </Popup>
    </div>
  );
};

export default BoardsWrapperDnD;
