import { combineReducers } from "redux";

import { reducer as cards } from "./cards";
import { reducer as decks } from "./decks";
import { reducer as panels } from "./panels";
import { reducer as field } from "./field";

const rootReducer = combineReducers({ cards, decks, panels, field });

export default rootReducer;
