import { createAction } from "@reduxjs/toolkit";

const setIsStackPanelOpen = createAction("panels/setIsStackPanelOpen", (isStackPanelOpen) => ({
  payload: isStackPanelOpen,
}));

const setIsDecksPanelOpen = createAction("panels/setIsDecksPanelOpen", (isStackPanelOpen) => ({
  payload: isStackPanelOpen,
}));

export default { setIsStackPanelOpen, setIsDecksPanelOpen };
